import * as React from "react";
import axios from "axios";
import { appConfig } from "../reducers/appConfig";
import { UserViewModel } from "../interfaces/UserViewMode";
import { RolePicker } from "./RolePicker";

interface State {
    errorMsg: string;
    success: boolean;
    selectedRoles: {[role: string]: any};
}

interface Props{
    user: UserViewModel;
}

class EditRoles extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        let sr: {[role: string]: any} = {};
        props.user.Roles.forEach(r => {
            sr[r] = {};
        })
        this.state = {
            errorMsg: "",
            success: false,
            selectedRoles: sr
        }
    }

    componentWillReceiveProps(props: Props){
        let sr: {[role: string]: any} = {};
        props.user.Roles.forEach(r => {
            sr[r] = {};
        })
        this.setState({...this.state, selectedRoles: sr})
    }

    changeRoles = () => {
        this.setState({...this.state, errorMsg: "", success: false});
        if(Object.keys(this.state.selectedRoles).length == 0){
            this.setState({...this.state, errorMsg: "Моля, изберете поне една роля", success: false});
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(appConfig.urls.setRoles, {
                Roles: Object.keys(this.state.selectedRoles),
                UserName: this.props.user.Username
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true})
            })
            .catch((e: any) => {
                this.setState({...this.state, errorMsg: "Възникна грешка", success: false})
            })
        })
    }
    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешно редактирани роли на потребител ${this.props.user.Username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg: "", success: false})}>Редактирай отново</button>
                </div>
            )
        }
        return(
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                <RolePicker
                    identityStore={appConfig.identityStore}
                    selectedRoles={this.state.selectedRoles}
                    onSelectionChanged={(selection) => {
                        this.setState({...this.state, selectedRoles: selection})
                    }}></RolePicker>
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                     this.changeRoles();
                }}>Запази промените</button>
            </div>
        )
    }
}

export default EditRoles;
