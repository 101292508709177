import * as React from "react";
import { ILog } from "../interfaces/ILog";
import { IUser } from "../interfaces/reducers/IAppStore";
import { IPopupDispatcher } from "../interfaces/IPopupDispatcher";
import Axios from "axios";
import { appConfig } from "../reducers/appConfig";
import OverlayLoader from "../Loading";
import DatePicker from "react-datepicker";

interface Props {
    user: IUser;
    back: () => void;
    popupDispatcher: IPopupDispatcher;
}

interface State {
    loading: boolean;
    pageLogs: Array<ILog>;
    page: number;
    pageSize: number;
    allLogsCount: number;
    Username: string;
    Statuses: number[];
    DateStart?: Date;
    DateEnd?: Date;
    MessageSearchTerm: string;
    Actions: string[];
    searchEmail: string;
    RequestSearchTerm: string;
    IP: string;
}

export class LogViewer extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            loading: true,
            pageLogs: [],
            page: 0,
            pageSize: 25,
            Username: "",
            Statuses: [],
            DateStart: new Date(new Date().setHours(0, 0, 0)),
            DateEnd: new Date(new Date().setHours(23, 59, 59)),
            MessageSearchTerm: "",
            Actions: [],
            allLogsCount: 0,
            searchEmail: "",
            RequestSearchTerm: "",
            IP: ""
        }
    }

    componentWillMount() {
        this.refreshUsers();
    }

    refreshUsers = () => {
        this.setState({...this.state, loading: true})
        Axios.post(appConfig.urls.queryLogs, {
            Page: this.state.page,
            PageSize: this.state.pageSize,
            Username: this.state.Username,
            RequestSearchTerm: this.state.RequestSearchTerm,
            Statuses: this.state.Statuses,
            DateStart: this.state.DateStart,
            DateEnd: this.state.DateEnd,
            MessageSearchTerm: this.state.MessageSearchTerm,
            Actions: this.state.Actions,
            Email: this.state.searchEmail

        }).then(r => {
            this.setState({ ...this.state, pageLogs: r.data.Logs, allLogsCount: parseInt(r.data.Count), loading: false })
        })
        .catch(e => {
            this.setState({ ...this.state, loading: false })
        })
    }
    getPagesNum(){
        return Math.floor(this.state.allLogsCount / this.state.pageSize) + (this.state.allLogsCount % this.state.pageSize == 0 ? 0 : 1 );
    }

    getTablePageControl = () => {
        var isOverflowing = ((this.state.page * this.state.pageSize) + this.state.pageLogs.length > this.state.allLogsCount && this.state.pageLogs.length == this.state.pageSize);
        return (
            <div style={{display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "200px",
                margin: "auto"}}>
                <button
                    disabled={this.state.page == 0}
                    onClick={() => {
                        this.setState({...this.state, page: this.state.page - 1}, () => {
                            this.refreshUsers();
                        })
                    }}
                >{"<"}</button>
                {this.state.page * this.state.pageSize + 1} - {isOverflowing ? this.state.allLogsCount : (this.state.page * this.state.pageSize) + this.state.pageLogs.length}
                <br></br>
                стр: {this.state.page + 1}/{this.getPagesNum()}
                <button
                    disabled={(this.state.page + 1) * this.state.pageSize > this.state.allLogsCount}
                    onClick={() => {
                        this.setState({...this.state, page: this.state.page + 1}, () => {
                            this.refreshUsers();
                        })
                    }}
                >{">"}</button>
            </div>
        )
    }

    register(){
        let logs: Array<JSX.Element> = [];
        this.state.pageLogs.forEach((log, i) => {
            logs.push(
                <tr key={i}>
                    <td>
                        {log.Id}
                    </td>
                    <td>
                        {log.User}
                    </td>
                    <td>
                        {log.Date}
                    </td>
                    <td>
                        {log.Change}
                    </td>
                    <td>
                        {log.Ip}
                    </td>
                </tr>
            )
        })
        if(logs.length == 0){
            return (
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px", padding: "20px",
                marginTop: "10px", border: "1px", backgroundColor: "#ff00002e"}}>Няма логове</div>
            )
        }
        return (
            <table style={{width: "100%"}} className="table table-striped">

                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>Потребител</th>
                        <th>Дата и час</th>
                        <th>Действие</th>
                        <th>IP</th>
                    </tr>
                    {logs}
                    <tr>
                        <td colSpan={6}>
                            {this.getTablePageControl()}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    getFilterMenu(){
        let filterMenuStyle: React.CSSProperties = {
            display: "flex",
            padding: "5px",
            alignItems: "center"
        }
        let filterMenuLabelStyle: React.CSSProperties = {
            margin: "0 10px"
        }
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Потребителско име</div>
                    <input className="form-control" value={this.state.Username} onChange={(e) => this.setState({...this.state, Username: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>IP</div>
                    <input className="form-control" value={this.state.IP} onChange={(e) => this.setState({...this.state, IP: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Съдържание в съобщението</div>
                    <input className="form-control" value={this.state.MessageSearchTerm} onChange={(e) => this.setState({...this.state, MessageSearchTerm: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Съдържание в заявката</div>
                    <input className="form-control" value={this.state.RequestSearchTerm} onChange={(e) => this.setState({...this.state, RequestSearchTerm: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>От</div>
                    <DatePicker
                        showTimeSelect={true}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat={"dd.MM.yyyy HH:mm"}
                        className="form-control"
                        selected={this.state.DateStart}
                        maxDate={this.state.DateEnd}
                        onChange={(v) => {
                            if(v){
                                this.setState({...this.state, DateStart: new Date(v)})
                            }
                            else{
                                this.setState({...this.state, DateStart: undefined})
                            }
                        }}
                        isClearable={true}
                        showYearDropdown
                    />
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>До</div>
                    <DatePicker
                        showTimeSelect={true}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat={"dd.MM.yyyy HH:mm"}
                        className="form-control"
                        selected={this.state.DateEnd}
                        minDate={this.state.DateStart}
                        onChange={(v) => {
                            if(v){
                                this.setState({...this.state, DateEnd: new Date(v)})
                            }
                            else{
                                this.setState({...this.state, DateEnd: undefined})
                            }
                        }}
                        isClearable={true}
                        showYearDropdown
                    />
                </div>
                <button style={{height: "40px"}} className="btn btn-dark"
                    onClick={() => {
                        this.setState({...this.state, page: 0}, () => {
                            this.refreshUsers();
                        })
                    }}>Филтрирай</button>
            </div>
        )
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", margin: "50px 100px 0 100px" }}>
                <OverlayLoader size="30px" show={this.state.loading} />
                <div style={{display: "flex", alignItems: "center", margin: "15px", justifyContent: "space-around", width: "400px"}}>
                    <button className="btn btn-secondary" onClick={this.props.back}>{"<< Назад"}</button>
                    <div>Регистър на потребителите</div>
                </div>
                {/* {this.getFilterMenu()} */}
                {this.register()}
            </div>
        )
    }
}
