import * as React from "react";
import axios from "axios";
import { appConfig } from "../reducers/appConfig";
import { UserViewModel } from "../interfaces/UserViewMode";

interface State {
    name: string;
    errorMsg: string;
    success: boolean;
}

interface Props{
    user: UserViewModel;
}

class SetName extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            name: "",
            errorMsg: "",
            success: false
        }
    }

    changeName = () => {
        this.setState({...this.state, errorMsg: "", success: false});
        if(!this.state.name){
            this.setState({...this.state, errorMsg: "Моля попълнете ново име", success: false});
            return;
        }

        return new Promise((resolve, reject) => {
            axios.post(appConfig.urls.setName, {
                Name: this.state.name,
                UserName: this.props.user.Username
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true})
            })
            .catch((e: any) => {
                console.warn(e.response)
                if(e.response && e.response.data && Object.keys(e.response.data).length > 0){
                    if(e.response.data.Errors){
                        this.setState({...this.state, errorMsg: e.response.data.Errors[0].Description, success: false})
                    }
                    else{
                        this.setState({...this.state, errorMsg: e.response.data[Object.keys(e.response.data)[0]][0], success: false})
                    }

                }
                else{
                    this.setState({...this.state, errorMsg: "Възникна грешка", success: false})
                }
            })
        })
    }
    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешна зададено ново име на ${this.props.user.Username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg: "", success: false, name: ""})}>Смени отново</button>
                </div>
            )
        }
        return(
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                <input
                    id="name"
                    value={this.state.name}
                    placeholder="Новo име"
                    onChange={e => {
                        this.setState({ ...this.state, name: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.changeName();
                        }
                    }}
                    type="text"
                    className="form-control" />
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                        this.changeName();
                }}>Смени паролата</button>
            </div>
        )
    }
}

export default SetName;
