import * as React from "react";
import axios from "axios";
import { appConfig } from "../reducers/appConfig";
import { UserViewModel } from "../interfaces/UserViewMode";
import { BoundryPicker } from "./BoundryPicker";

interface State {
    errorMsg: string;
    success: boolean;
    selectedBoundries: {[role: string]: any};
}

interface Props{
    user: UserViewModel;
}

class EditRoles extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        let sr: {[rest: string]: any} = {};
        props.user.AdminRestrictions.forEach((r: { BoundryId: string | number; }) => {
            sr[r.BoundryId] = {};
        })
        this.state = {
            errorMsg: "",
            success: false,
            selectedBoundries: sr
        }
    }

    componentWillReceiveProps(props: Props){
        let sr: {[role: string]: any} = {};
        props.user.AdminRestrictions.forEach((r: { BoundryId: string | number; }) => {
            sr[r.BoundryId] = {};
        })
        this.setState({...this.state, selectedBoundries: sr})
    }

    changeRoles = () => {
        this.setState({...this.state, errorMsg: "", success: false});
        if(Object.keys(this.state.selectedBoundries).length == 0){
            this.setState({...this.state, errorMsg: "Моля, изберете поне една зона", success: false});
            return;
        }
        let tmp = [];
        for(let r in this.state.selectedBoundries){
            tmp.push(+r);
        }
        console.log(tmp);
        this.setState({...this.state, errorMsg: "", success: true})

        return new Promise((resolve, reject) => {
            axios.post(appConfig.urls.setBoundries, {
                BoundryIds: Object.keys(this.state.selectedBoundries).map(key => parseInt(key)),
                UserId: this.props.user.Id,
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true})
            })
            .catch((e: any) => {
                this.setState({...this.state, errorMsg: "Възникна грешка", success: false})
            })
        })
    }
    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешно редактирани Зони на потребител ${this.props.user.Username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg: "", success: false})}>Редактирай отново</button>
                </div>
            )
        }
        return(
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" , height: "1000px"}}>
                <BoundryPicker
                    identityStore={appConfig.identityStore}
                    selectedBoundries={this.state.selectedBoundries}
                    onSelectionChanged={(selection) => {
                        this.setState({...this.state, selectedBoundries: selection})
                    }}></BoundryPicker>
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                     this.changeRoles();
                }}>Запази промените</button>
            </div>
        )
    }
}

export default EditRoles;
