import * as React from "react";
import { IIdentityStore } from "../reducers/appConfig";

interface Props {
    onSelectionChanged: (newSelection: {[role: string]: any}) => void;
    identityStore: IIdentityStore;
    selectedRoles: {[role: string]: any}
}

interface State {
    advanced: boolean;
    selectedRoles: {[role: string]: any}
}

export class RolePicker extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            advanced: false,
            selectedRoles: props.selectedRoles
        }
    }   

    componentWillReceiveProps(props: Props){
        this.setState({...this.state, selectedRoles: props.selectedRoles})
    }

    hasGroups(): boolean {
        let has = false;
        for(let g in this.props.identityStore.roles){
            if(Object.keys(this.props.identityStore.roles[g].groups).length > 0){
                has = true;
                break
            }
        }
        return has;
    }

    getGroupedRoleSelector(){

    }

    getAdvancedRoleSelector(){
        let checkboxes: JSX.Element[] = [];
        let selectedRoles = {...this.state.selectedRoles};
        for (let role in this.props.identityStore.roles) {
            checkboxes.push(
                <div key={role} style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                    <input
                        type="checkbox"
                        style={{ transform: "scale(1.6)", marginRight: "4px" }}
                        checked={!!selectedRoles[role]}
                        onChange={() => {
                            if (selectedRoles[role]) {
                                delete selectedRoles[role]
                            }
                            else {
                                selectedRoles[role] = {}
                            }
                            this.setState({...this.state, selectedRoles: selectedRoles}, () => {
                                this.props.onSelectionChanged(selectedRoles)
                            })
                            
                        }}></input>
                    <div>{this.props.identityStore.roles[role].label}</div>
                </div>
            )
        }
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h5>Изберете роли:</h5>
                {checkboxes}
            </div>
        );
    }

    getRoleSelector(hasGroups: boolean){
        return (!hasGroups || this.state.advanced) ? this.getAdvancedRoleSelector() : this.getGroupedRoleSelector();
    }

    getAdvancedToggler(){
        return (
            <button onClick={() => this.setState({...this.state, advanced: !this.state.advanced})}>
                {this.state.advanced ? "Излез от разширеното търсене" : "Разширено търсене"}
            </button>
        )
    }
    render(){
        let hasGroups = this.hasGroups();
        return(
            <div>
                {hasGroups ? this.getAdvancedToggler() : null}
                {this.getRoleSelector(hasGroups)}
            </div>
        )
    }
}