import React from "react";

interface Props {
    size?: string;
    show?: boolean;
}

interface State {
    size?: string;
    show?: boolean;
}

class OverlayLoader extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            show: this.props.show === undefined ? true : this.props.show,
            size: this.props.size === undefined ? "60px" : this.props.size
        }
    }
    componentWillReceiveProps(props: Props){
        this.setState({
            ...this.state, show: props.show
        })
    }
    render(){
        let borderWidth = this.props.size ? (parseInt(this.props.size.replace("px", "")) / 8).toString() + "px" : "60px";
        return (
            <div className={"loader" + (this.state.show ? " loader-disabled" : "")} style={{
                    opacity: this.state.show ? 1: 0,
                    zIndex: this.state.show ? 20: -1
                }}>
                <div style={{ height: this.props.size, width: this.props.size, backgroundSize: this.props.size, borderWidth: borderWidth}}></div>
            </div>
        )
    }

}
export default OverlayLoader;