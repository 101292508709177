import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import rootReducer from "./rootReducer";
import { Store, createStore, applyMiddleware, Middleware } from 'redux';
import thunk from "redux-thunk";
import Axios from 'axios';
import {appConfig} from './reducers/appConfig';

const getStore = () => {
    let baseMiddleware: Array<Middleware> = [thunk];
    let store: Store;
    store = createStore(rootReducer, applyMiddleware(...baseMiddleware));
    window.addEventListener("resize", function() {
        store.dispatch({
            type: "Window_resized",
            payload: window.innerHeight < 500 || window.innerWidth < 500
        });
    });
    return store;
}
ReactDOM.render(<Provider store={getStore()}><App /></Provider>, document.getElementById('root'));
serviceWorker.unregister();
