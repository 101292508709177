import * as React from "react";
import { connect } from "react-redux";
import { singIn } from '../actions/userInfo';
import { IAppStore, IUserInfo } from '../interfaces/reducers/IAppStore';
import OverlayLoader from '../Loading';


interface OwnProps {
    userInfo: IUserInfo;
}

interface DispatchProps {
    singIn: typeof singIn;
}

interface ParentProps {
    register: () => void;
}

interface State {
    username: string;
	password: string;
}

type Props = ParentProps & DispatchProps & OwnProps;

class Login extends React.Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {
            username: "",
            password: ""
        }
    }
    singIn = () => {

    }
    render(){
        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                <OverlayLoader size="30px" show={this.props.userInfo.loading} />
                <input
                    id="username"
                    value={this.state.username}
                    placeholder="Потребителско име"
                    onChange={e => {
                        this.setState({ ...this.state, username: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.props.singIn(this.state.username, this.state.password);
                        }
                    }}
                    className="form-control" />
                <input
                    id="password"
                    value={this.state.password}
                    placeholder="Парола"
                    onChange={e => {
                        this.setState({ ...this.state, password: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.props.singIn(this.state.username, this.state.password);
                        }
                    }}
                    type="password"
                    className="form-control" />
                {this.props.userInfo.signInError ? <div style={{color: "red"}}>{this.props.userInfo.signInError.message}</div> : null}
                <button className="btn btn-success" onClick={() => {

                        this.props.singIn(this.state.username, this.state.password);
                    
                }}>Вход</button>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppStore) => ({
    userInfo: state.userInfo
})

export default connect<OwnProps, DispatchProps, ParentProps, IAppStore>(mapStateToProps, {singIn})(Login as any);