import * as React from "react";
import { IUser } from "../interfaces/reducers/IAppStore";
import ChangePassword from "./ChangePassword";
import CreateUser from "./CreateUser";
import UserRegister from "./UserRegister";
import axios from "axios";
import { appConfig } from "../reducers/appConfig";
import { IPopupDispatcher } from "../interfaces/IPopupDispatcher";
import { LogViewer } from "./LogViewer";

interface Props {
    user: IUser;
    signOut: () => void;
    popupDispatcher: IPopupDispatcher;
}

interface State {
    selectedView: string;
    selectedCreateUser: string;
}

class Content extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            selectedView: "main",
            selectedCreateUser: ""
        }
    }

    back = () => {
        this.setState({...this.state, selectedView: "main"})
    }

    getAdminButtons = () => {
        if(this.props.user.isAdmin){
            return(
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", marginTop: "15px", height: "250px", justifyContent: "space-around" }}>
                    {this.props.user.isAdmin ? 
                        <button className="btn btn-secondary" 
                            onClick={() => this.setState({...this.state, selectedCreateUser: "ca", selectedView: "createUser"})}>Създай потребител</button> : null}
                        <button className="btn btn-primary" onClick={() => this.setState({...this.state, selectedView: "register"})}>Регистър потребители</button>
                        <button className="btn btn-primary" onClick={() => this.setState({...this.state, selectedView: "logs"})}>Логове</button>
                </div>
            )
        }
    }

    getMainView = () => {
        return (
            <div style={{margin: "100px 100px 0 100px"}}>
                <div>{`Здравей ${this.props.user.Name}`}</div>
                <div>Тук може да смениш паролата си:</div>
                <ChangePassword user={this.props.user}/>
                {this.getAdminButtons()}
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", marginTop: "15px" }}>
                    <button className="btn btn-danger" onClick={this.props.signOut}>Изход</button>
                </div>
            </div>
        )
    }

    render(){
        if(this.state.selectedView == "main"){
            return this.getMainView()
        }
        if(this.state.selectedView == "createUser"){
            return <CreateUser user={this.props.user} back={this.back} selectedCreateUser={this.state.selectedCreateUser}></CreateUser>
        }
        if(this.state.selectedView == "register"){
            return <UserRegister popupDispatcher={this.props.popupDispatcher} back={this.back} user={this.props.user}></UserRegister>
        }
        if(this.state.selectedView == "logs"){
            return <LogViewer popupDispatcher={this.props.popupDispatcher} back={this.back} user={this.props.user}></LogViewer>
        }
        else{
            return this.getMainView()
        }
    }
}

export default Content;