import * as React from "react";
import LoginView from "./LoginView";
import RegisterView from "./RegisterView";
import { appConfig } from "../reducers/appConfig";

interface State {
    login: boolean;
}

interface Props {

}

class LoginRegisterSplit extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            login: !appConfig.register
        }
    }

    toggleLoginView = () => {
        this.setState({...this.state, login: !this.state.login})
    }

    render(){
        return (
            <div style={{margin: "80px 80px 0 80px"}}>
                {
                    this.state.login ?
                    <LoginView register={this.toggleLoginView}></LoginView>
                    :
                    <RegisterView register={this.toggleLoginView}></RegisterView>
                }
            </div>
        )
    }
}

export default LoginRegisterSplit;