import * as React from "react";
import OverlayLoader from "../Loading";
import { appConfig } from "../reducers/appConfig";
import axios from "axios";
import { IUser } from "../interfaces/reducers/IAppStore";
import { RolePicker } from "./RolePicker";

interface Props {
    back: () => void;
    selectedCreateUser: string;
    user: IUser;
}

interface State {
    username: string;
    password: string;
    name: string;
    passwordConf: string;
    errorMsg: string;
    success: boolean;
    loading: boolean;
    selectedRoles: {[role: string]: any}
}

class CreateUser extends React.Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {
            username: "",
            password: "",
            passwordConf: "",
            name: "",
            errorMsg: "",
            success: false,
            loading: false,
            selectedRoles: {}
        }
    }

    registerUser = () => {
        this.setState({...this.state, errorMsg: "", success: false, loading: true})
        if(!this.state.username){
            this.setState({...this.state, errorMsg: "Моля, попълнете потребителско име", success: false, loading: false})
            return;
        }
        if(!this.state.password || !this.state.passwordConf){
            this.setState({...this.state, errorMsg: "Моля, попълнете паролите", success: false, loading: false})
            return;
        }
        if(this.state.password != this.state.passwordConf){
            this.setState({...this.state, errorMsg: "Паролата за потвърждение не е същата", success: false, loading: false})
            return;
        }
        if(!this.state.name){
            this.setState({...this.state, errorMsg: "Моля, попълнете името", success: false, loading: false})
            return;
        }
        if(Object.keys(this.state.selectedRoles).length == 0){
            this.setState({...this.state, errorMsg: "Моля, изберете поне една роля", success: false, loading: false})
            return;
        }
        return new Promise((resolve, reject) => {
            let url = appConfig.urls.registerUser;
            
            axios.post(url, {
                UserName: this.state.username,
                Password: this.state.password,
                PasswordConfirmation: this.state.passwordConf,
                Name: this.state.name,
                Roles: Object.keys(this.state.selectedRoles)
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true, loading: false})
            })
            .catch((e: any) => {
                if(e.response && e.response.data && Object.keys(e.response.data).length > 0){
                    if(e.response.data[Object.keys(e.response.data)[0]].Description){
                        this.setState({...this.state, errorMsg: e.response.data[Object.keys(e.response.data)[0]].Description, success: false, loading: false})
                    }
                    else{
                        this.setState({...this.state, errorMsg: e.response.data[Object.keys(e.response.data)[0]][0], success: false, loading: false})
                    }
                    
                }
                else{
                    this.setState({...this.state, errorMsg: "Възникна грешка", success: false, loading: false})
                }
            })
        })
    }

    getInputs(){
        return (
            <React.Fragment>
                <input
                    id="name"
                    value={this.state.name}
                    placeholder="Име"
                    onChange={e => {
                        this.setState({ ...this.state, name: e.target.value });
                    }}
                    className="form-control" />
                <input
                    id="username"
                    value={this.state.username}
                    placeholder="Потребителско име"
                    onChange={e => {
                        this.setState({ ...this.state, username: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.registerUser();
                        }
                    }}
                    className="form-control" />
                <input
                    id="password"
                    value={this.state.password}
                    placeholder="Парола"
                    onChange={e => {
                        this.setState({ ...this.state, password: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.registerUser();
                        }
                    }}
                    type="password"
                    className="form-control" />
                <input
                    id="confirmationPassword"
                    value={this.state.passwordConf}
                    placeholder="Потвърдете паролата"
                    onChange={e => {
                        this.setState({ ...this.state, passwordConf: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.registerUser();
                        }
                    }}
                    type="password"
                    className="form-control" />
            </React.Fragment>
        )
    }

    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешна регистрация на акаунт ${this.state.username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg:"", success: false, username: "", name: "", password: "", passwordConf: ""})}>Създай нов потребител</button>
                </div>
            )
        }


        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", position: "relative", margin: "0 150px 150px 150px" }}>
                <div style={{display: "flex", alignItems: "center", margin: "15px", justifyContent: "space-around", width: "400px"}}>
                    <button className="btn btn-secondary" onClick={this.props.back}>{"<< Назад"}</button>
                </div>
                <OverlayLoader size="30px" show={this.state.loading} />
                    {this.getInputs()}
                    <RolePicker 
                    identityStore={appConfig.identityStore}
                    selectedRoles={this.state.selectedRoles} 
                    onSelectionChanged={(selection) => {
                        this.setState({...this.state, selectedRoles: selection})
                    }}></RolePicker>
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                    this.registerUser();
                }}>Регистрация</button>
            </div>
        )
    }
}

export default CreateUser;